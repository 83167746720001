import { Link } from "@reach/router";
import { ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const homeIcon = require("../../../image/icon/home_ic.svg");
const jobsIcon = require("../../../image/icon/jobs_ic.svg");
const starIcon = require("../../../image/icon/star_ic.svg");
const mailIcon = require("../../../image/icon/mail.svg");
const companyIcon = require("../../../image/icon/companies.svg");
const offerIcon = require("../../../image/icon/offer.svg");

// Menu on sidebar when we open the candidate panel
export const CandidateSidebar = ({ setActive }) => {
  return (
    <div className="aside__top">
      <div className="aside__top__part">
        <ul className="aside__top__list">
          <li className="aside__top__item">
            <ShowToolTipComponent text="Home" position="right">
              <Link
              to="/"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/")}
            >
              <img src={homeIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Home</p>
            </Link>
            </ShowToolTipComponent>
          </li>
          <li className="aside__top__item">
            <ShowToolTipComponent text=" View Mails" position="right">
              <Link
              to="/recruitment/mail-box"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/recruitment/mail-box")}
            >
              <img src={mailIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Mailbox</p>
            </Link>
            </ShowToolTipComponent>
          </li>
          {/* <li className="aside__top__item">
            <Link
              to="/calendar"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/calendar")}
            >
              <img src={calendarIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Calendar</p>
            </Link>
          </li> */}
          {/* <li className="aside__top__item">
            <Link
              to="/files"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/files")}
            >
              <img src={filesIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Files</p>
            </Link>
          </li> */}
        </ul>
      </div>
      <div className="aside__top__part">
        <ul className="aside__top__list">
          <li className="aside__top__item">
            <ShowToolTipComponent text="View Jobs" position="right">
              <Link
              to="/candidate/jobs"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidate/jobs")}
            >
              <img src={jobsIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Jobs</p>
            </Link>
            </ShowToolTipComponent>
          </li>
          <li className="aside__top__item">
            <ShowToolTipComponent text="View Companies" position="right">
              <Link
              to="/candidate/companies"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidate/companies")}
            >
              <img src={companyIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Companies</p>
            </Link>
            </ShowToolTipComponent>
          </li>
        </ul>
      </div>
      <div className="aside__top__part">
        <ul className="aside__top__list">
          {/* <li className="aside__top__item">
            <Link
              to="/candidate/assessments"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidate/assessments")}
            >
              <img
                src={homeIcon}
                alt="Assessment Home Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Assessments</p>
            </Link>
          </li> */}
          <li className="aside__top__item">
            <ShowToolTipComponent text="Scoreboard" position="right">
              <Link
              to="/candidate/scoreboard"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidate/scoreboard")}
            >
              <img
                src={starIcon}
                alt="Assessment Scoreboard Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Scoreboard</p>
            </Link>
            </ShowToolTipComponent>
          </li>
        </ul>
      </div>
      <div className="aside__top__part">
        <ul className="aside__top__list">
          {/* <li className="aside__top__item">
            <Link
              to="/screening-hiring/home"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/screening-hiring/home")}
            >
              <img
                src={homeIcon}
                alt="Screening & Hiring Home Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Home</p>
            </Link>
          </li> */}
          <li className="aside__top__item">
            <ShowToolTipComponent text="View Offers" position="right">
              <Link
              to="/candidate/offers"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidate/offers")}
            >
              <img
                src={offerIcon}
                alt="Assessment Scoreboard Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Offers</p>
            </Link>
            </ShowToolTipComponent>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CandidateSidebar;
