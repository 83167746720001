// src/Tooltip.js
import { ToolTipProps } from '../types/tooltip-types';

import Tooltip from '@mui/material/Tooltip';


const ShowTooltip = ({ children, text,position='bottom' }:ToolTipProps) => {

    return (
        <Tooltip title={text} placement={position} arrow sx={{padding:0}}>
            <div style={{display:'flex'}}>{children}</div>
        </Tooltip>
    );
};

export default ShowTooltip;
