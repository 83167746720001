import { useState } from "react";
import { Link } from "@reach/router";
const chatIcon = require("../../../image/icon/chat_ic.svg");
const closeIcon = require("../../../image/icon/close_ic.svg");
const faqIcon = require("../../../image/icon/faq_ic.svg");
const feedbackIcon = require("../../../image/icon/feedback_ic.svg");
const moreIcon = require("../../../image/icon/more_ic.svg");

const setActiveBottom = ({ location: { pathname } }, active) => {
  if (pathname === active) {
    return { className: "aside__bottom__link active" };
  }
};

export const MoreMenu = () => {
  const [moreMenu, setMoreMenu] = useState(false);
  return (
    <div className="aside__bottom">
      {!moreMenu && (
        <div className="aside__bottom__part">
          <ul className="aside__bottom__list">
            <li className="aside__bottom__item">
              <a
                href="#"
                className="aside__bottom__link"
                onClick={(e) => {
                  e.preventDefault();
                  setMoreMenu(true);
                }}
              >
                <img src={moreIcon} alt="" className="aside__bottom__icon" />
                <p className="aside__bottom__text">More</p>
              </a>
            </li>
          </ul>
        </div>
      )}
      {moreMenu && (
        <div className="aside__bottom__part bottom">
          <ul className="aside__bottom__list">
            <li className="aside__bottom__item">
              <Link
                to="/faq"
                className="aside__bottom__link"
                getProps={(props) => setActiveBottom(props, "/faq")}
              >
                <img src={faqIcon} alt="" className="aside__bottom__icon" />
                <p className="aside__bottom__text">Help center</p>
              </Link>
              <li className="aside__bottom__item">
                <Link
                  to="/live-chat"
                  className="aside__bottom__link"
                  getProps={(props) => setActiveBottom(props, "/live-chat")}
                >
                  <img src={chatIcon} alt="" className="aside__bottom__icon" />
                  <p className="aside__bottom__text">Live chat</p>
                </Link>
              </li>
            </li>
            <li className="aside__bottom__item">
              <Link
                to="/feedback"
                className="aside__bottom__link"
                getProps={(props) => setActiveBottom(props, "/feedback")}
              >
                <img
                  src={feedbackIcon}
                  alt=""
                  className="aside__bottom__icon"
                />
                <p className="aside__bottom__text">Feedbacks</p>
              </Link>
            </li>
            <li className="aside__bottom__item">
              <Link
                to=""
                className="aside__bottom__link close"
                onClick={(e) => {
                  e.preventDefault();
                  setMoreMenu(false);
                }}
                getProps={(props) => setActiveBottom(props, "/placeholder")}
              >
                <img src={closeIcon} alt="" className="aside__bottom__icon" />
                <p className="aside__bottom__text">Close</p>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MoreMenu;
